import { Route, Routes, useNavigate, useLocation } from "react-router-dom";
import "./bootstrap.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Portfolio from "./pages/portfolio";
import Calandar from "./pages/calandar";
import Overview from "./pages/overview";
import Screener from "./pages/screener";
import Error from "./pages/error";
import Home from "./pages/home";
import { useEffect, useState } from "react";
import axios from "axios";
import axiosThrottle from "axios-request-throttle";
import ProtectedRoutes from "./ProtectedRoutes";
import Navbar from "./components/navbar";
import "./App.css";
import SettingsPage from "./pages/settings";
import Target from "./pages/target";
import { DataProvider } from "./DataContext";

axiosThrottle.use(axios, { requestsPerSecond: 5 });

function App() {
  const [portData, setPortData] = useState([]);
  const [update, setUpdate] = useState(false);
  const [value, setValue] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [version, setVersion] = useState("free");
  const [div, setDiv] = useState(0);

  const navigate = useNavigate();

  function calculateTotalPrice(data) {
    let totalPrice = 0;

    for (let i = 0; i < data.length; i++) {
      const innerArray = data[i];

      // Calculate total shares bought
      const totalSharesBought = innerArray.buyData.reduce(
        (total, item) => total + (item.noOfSharesBought || 0),
        0
      );

      // Calculate total shares sold
      const totalSharesSold = Array.isArray(innerArray.soldData)
        ? innerArray.soldData.reduce(
            (total, item) => total + (item.noOfSharesSold || 0),
            0
          )
        : 0;

      // Remaining shares
      const remainingShares = totalSharesBought - totalSharesSold;

      // Calculate value of remaining shares
      const arrayTotal = remainingShares * innerArray.price;

      // Add to total price
      totalPrice += arrayTotal;
    }

    // Return the total value
    return totalPrice;
  }

  function getAnnualDividendValue(arr) {
    let total = 0;
    for (let i = 0; i < arr.length; i++) {
      const dividends = arr[i].divDate;
      for (let j = 0; j < dividends.length; j++) {
        isWithinLastYear(new Date(dividends[j].date)) &&
          (total += dividends[j].dividend);
      }
    }
    return total;
  }
  function isWithinLastYear(inputDate) {
    // Get the current date
    const currentDate = new Date();

    // Calculate the date from exactly one year ago
    const lastYearDate = new Date();
    lastYearDate.setFullYear(currentDate.getFullYear() - 1);

    // Compare the input date with the date from one year ago
    return inputDate >= lastYearDate && inputDate <= currentDate;
  }

  function isNewDay() {
    // Retrieve the last loaded date from localStorage
    var lastLoadedDate = localStorage.getItem("lastLoadedDate");

    // Get the current date
    var currentDate = new Date().toLocaleDateString();

    // If lastLoadedDate is not set or it's different from the current date, it's a new day
    if (lastLoadedDate != currentDate) {
      // Store the current date in localStorage for future reference
      localStorage.setItem("lastLoadedDate", currentDate);
      axios
        .get(process.env.REACT_APP_API_UPDATE, {
          params: {
            user: JSON.parse(localStorage.getItem("data")).email,
            token: JSON.parse(localStorage.getItem("data")).token,
          },
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("data")).token
            }`,
          },
        })
        .then(() => setUpdate(!update))
        .catch((err) => console.log(err));
      return;
    }

    return;
  }

  useEffect(() => {
    setIsLoading(true);
    document.body.style.backgroundColor = "rgba(245,238,211,255)";

    //  if (JSON.parse(localStorage.getItem("data")).token) {
    //    isNewDay();
    // }
    localStorage.getItem("data")
      ? axios
          .get(process.env.REACT_APP_API_GET, {
            params: {
              user: JSON.parse(localStorage.getItem("data")).email,
              token: JSON.parse(localStorage.getItem("data")).token,
            },
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${
                JSON.parse(localStorage.getItem("data")).token
              }`,
            },
          })
          .then((res) => {
            setIsLoading(false);

            setPortData(res.data[0]);
            setValue(calculateTotalPrice(res.data[0]));
            setVersion(res.data[1].version);
            setDiv(getAnnualDividendValue(res.data[0]));
          })
          .catch(() => {
            navigate("/home", { replace: true });
          })
      : console.log("Not Logged in yet");

    return;
  }, [update]);

  const location = useLocation();

  const getBackgroundClass = () => {
    switch (location.pathname) {
      case "/home":
        return "home-background";
      case "/portfolio":
        return "port-background";
      case "/calendar":
        return "cal-background";
      case "/overview":
        return "port-background";
      case "/targets":
        return "port-background";
      default:
        return "home-background";
    }
  };

  return (
    <DataProvider>
      <div className={`App ${getBackgroundClass()}`} style={{ minWidth: 1100 }}>
        <Navbar />
        <Routes>
          <Route path="" element={<Home />} />
          <Route path="home" element={<Home />} />
          <Route
            path="portfolio"
            element={
              <ProtectedRoutes>
                <Portfolio
                  data={portData}
                  setUpdate={setUpdate}
                  update={update}
                  value={value}
                  isLoading={isLoading}
                  version={version}
                  div={div}
                />
              </ProtectedRoutes>
            }
          />
          <Route
            path="calendar"
            element={
              <ProtectedRoutes>
                <Calandar data={portData} update={update} />
              </ProtectedRoutes>
            }
          />
          <Route
            path="overview"
            element={
              <ProtectedRoutes>
                <Overview data={portData} value={value} />
              </ProtectedRoutes>
            }
          />
          <Route
            path="settings"
            element={
              <ProtectedRoutes>
                <SettingsPage />
              </ProtectedRoutes>
            }
          />
          <Route
            path="targets"
            element={
              <ProtectedRoutes>
                <Target data={portData} div={div} />
              </ProtectedRoutes>
            }
          />
          <Route path="screener" element={<Screener />} />
          <Route path="error" element={<Error />} />

          <Route
            path="*"
            element={
              <div style={{ paddingTop: 100 }}>
                <h1>PAGE NOT FOUND!</h1>
              </div>
            }
          />
        </Routes>
      </div>
    </DataProvider>
  );
}

export default App;
